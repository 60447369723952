interface IStorageExpiration {
    expirationDays: number;
    expirationDate: Date;
}

class StorageExpiration {
    private store: Storage;
    private expiration: Map<string, IStorageExpiration> = new Map();
    private broadcastChannel: BroadcastChannel;

    private defaultExpirationDays = 30;

    private storageKeyName = 'expirationDates';
    private expirationDateKey = 'expirationDate';
    private broadCastChannelPostfix = 'ExpirationChannel';

    public constructor(storage: Storage) {
        this.store = storage;

        // Turn date strings back into Date objects
        const reviver = (key: string, value: string): string | Date | null => {
            if (key !== this.expirationDateKey) return value;

            const date = new Date(value);
            return isNaN(date.valueOf()) ? null : date;
        };

        const expiration = this.store.getItem(this.storageKeyName) ?? '[]';

        try {
            const revivedJSON: IStorageExpiration[] = JSON.parse(
                expiration,
                reviver
            );
            this.expiration = new Map(Object.entries(revivedJSON));
        } catch (error) {
            console.error('Error parsing JSON in constuctor:', error);
        }

        const broadCastChannelName = `${
            this.store === window.localStorage
                ? 'localStorage'
                : 'sessionStorage'
        }${this.broadCastChannelPostfix}`;

        this.broadcastChannel = new BroadcastChannel(broadCastChannelName);

        this.broadcastChannel.onmessage = (event) => {
            try {
                this.expiration = event.data;
            } catch (error) {
                console.error('Error in broadcast message data:', error);
            }
        };
    }

    public add(expirationDays: number | undefined, ...keys: string[]) {
        keys.forEach((key) =>
            this.setExpiration(
                expirationDays || this.defaultExpirationDays,
                key
            )
        );

        this.storeExpiration();
    }

    public delete(...keys: string[]) {
        keys.forEach((key) => this.expiration.delete(key));
        this.storeExpiration();
    }

    public update(...keys: string[]) {
        keys.forEach((key) => {
            const { expirationDays: expirationDays } =
                this.expiration.get(key) || {};
            expirationDays && this.setExpiration(expirationDays, key);
        });

        this.storeExpiration();
    }

    public removeExpiredItems() {
        const currentDate = new Date();

        const expiredItems = [...this.expiration].flatMap(
            ([key, { expirationDate }]) =>
                currentDate > expirationDate ? key : []
        );

        expiredItems.forEach((expiredItem) =>
            this.store.removeItem(expiredItem)
        );

        this.delete(...expiredItems);
    }

    private setExpiration(expirationDays: number, key: string) {
        const date = new Date();
        const expirationDate = new Date(
            date.setDate(date.getDate() + expirationDays)
        );

        this.expiration.set(key, { expirationDays, expirationDate });
    }

    private storeExpiration() {
        this.store.setItem(
            this.storageKeyName,
            JSON.stringify(Object.fromEntries(this.expiration))
        );

        this.broadcastChannel.postMessage(this.expiration);
    }
}

export default StorageExpiration;
export type { IStorageExpiration };
